import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Button from "@remar/shared/dist/components/Button";

import LackOfFundsDiscount from "@remar/shared/dist/components/LackOfFundsDiscount";
import Stepper from "@remar/shared/dist/components/Stepper";
import { ExternalIntegrationIds } from "@remar/shared/dist/constants";
import useCaptchaToken from "@remar/shared/dist/hooks/useVerifyCaptcha";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { detectIncognito } from "detectincognitojs";
import { Form, Formik } from "formik";
import useAnalyticsEventTracker from "hooks/googleAnalytics";
import { usePostAffiliateProTrack } from "hooks/usePostAffiliateProTrack";
import { isEmpty } from "lodash";

import ReactPixel from "react-facebook-pixel";

import { useHistory, useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import {
	changeSubscriptionType,
	fetchCountries,
	getCourses,
	getFullState,
	setError,
	setIsLoading,
	setPapCookie,
	setSubscriptionTypesForSignup,
	signUp,
	validateCoupon,
	validateDuplicateEmail
} from "store/features/Auth/authSlice";
import { USA_COUNTRY_ID } from "store/features/Auth/constants";
import { UserSignUpVerificationDto } from "store/services";

import { PixelEvent, getCallbackUrl, setTiktokEvent } from "modules/utils";

import SignUpLayout from "../SignUpLayout";
import FormActions from "../components/FormActions";
import { AccountFormDetails, PaymentForm, ShippingForm } from "../components/Forms";
import { GuestSignUpError } from "../components/GuestSignUpError";
import SignUpSuccessLeft from "../components/SignUpSuccessLeft";
import SignUpSuccessRight from "../components/SignUpSuccessRight";
import Summary from "../components/Summary";
import { PaymentSchema, ShippingSchema, payedSignupSchema } from "../components/schemas";

import { useStyles } from "../components/styles";
import { signUpFormKeys, validateFormValues } from "../components/utils";

type Offer = {
	discountAmount: number;
	discountId: number;
};

const initialFormValues = {
	firstName: "",
	lastName: "",
	email: "",
	password: "",
	countryId: "",
	address1: "",
	address2: "",
	city: "",
	state: "",
	zip: "",
	phoneNumber: "",
	terms: false
};
const steps = [{ label: "Account" }, { label: "Shipping" }, { label: "Payment" }];
enum Steps {
	ACCOUNT,
	SHIPPING,
	PAYMENT,
	SIGNUP_ERROR,
	SIGNUP_SUCCESS
}
const PaidSignUp = () => {
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const elements = useElements();
	const stripe = useStripe();
	const { handleGenerateCaptchaToken } = useCaptchaToken();
	const analytics = useAnalyticsEventTracker("User");
	const { courseId, subscriptionTypeId } = useParams<{ courseId?: string; subscriptionTypeId?: string }>();
	const history = useHistory();
	const callBackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);

	const {
		errorMessage,
		tryAgainEnabled,
		isLoading,
		courses,
		guestSignUpData,
		selectedShippingPlan,
		multipleShippingPlan,
		subscriptionTypes,
		selectedCountryId,
		userSubscriptionTypeId,
		userSubscriptionTypeAddonIds,
		shippingApplicable,
		validatedCoupon
	} = useAppSelector(getFullState);
	const { paymentProviderAccount } = guestSignUpData as UserSignUpVerificationDto;
	const { paymentSource } = paymentProviderAccount || {};

	const [isIncognito, setIsIncognito] = useState(false);
	const [activeStep, setActiveStep] = useState(Steps.ACCOUNT);
	const [startDate, setStartDate] = useState(new Date());
	const [signupSuccessUserData, setSignupSuccessUserData] = useState({});
	const [activeSubscription, setActiveSubscription] = useState(subscriptionTypes[0]);
	const [, setFreeTrial] = useState(false);
	const [offer, setOffer] = useState<Offer | undefined>(undefined);

	useEffect(() => {
		if (subscriptionTypes.length && subscriptionTypeId) {
			let currentSubscriptionType = subscriptionTypes.find(({ id }) => id === +subscriptionTypeId);
			if (!currentSubscriptionType) {
				currentSubscriptionType = subscriptionTypes.find(({ isDefault }) => isDefault) || subscriptionTypes[0];
			}
			setActiveSubscription(currentSubscriptionType);
			dispatch(changeSubscriptionType(currentSubscriptionType.id as number));
			dispatch(fetchCountries(currentSubscriptionType.id as number));
			dispatch(getCourses());
		}
	}, [subscriptionTypes, subscriptionTypeId, dispatch]);

	useEffect(() => {
		detectIncognito().then(({ isPrivate }) => setIsIncognito(isPrivate)); // it doesn't work in firefox
		dispatch(
			setSubscriptionTypesForSignup({
				courseId: +courseId!,
				isTrial: false
			})
		);
	}, [courseId, dispatch]);

	usePostAffiliateProTrack(v => dispatch(setPapCookie(v)));

	useEffect(() => {
		switch (activeStep) {
			case Steps.ACCOUNT: {
				setTiktokEvent(PixelEvent.AddToCart);
				ReactPixel.track(PixelEvent.AddToCart);
				break;
			}
			case Steps.PAYMENT: {
				setTiktokEvent(PixelEvent.AddPaymentInfo);
				ReactPixel.track(PixelEvent.AddPaymentInfo);
				break;
			}
			case Steps.SHIPPING: {
				setTiktokEvent(PixelEvent.InitiateCheckout);
				ReactPixel.track(PixelEvent.InitiateCheckout);
				break;
			}
		}
	}, [activeStep]);

	const validationSchema = useMemo(() => {
		switch (activeStep) {
			case Steps.PAYMENT: {
				return PaymentSchema;
			}
			case Steps.SHIPPING: {
				return ShippingSchema;
			}
			case Steps.ACCOUNT: {
				return payedSignupSchema;
			}
		}
	}, [activeStep]);

	const setSignUpSuccessUser = response => {
		const mainSubTypeEIDItems = activeSubscription?.subTypeEIDItems!.find(
			i => i.integrationId == ExternalIntegrationIds.Stripe && i.parentId === null
		);
		const selectedCourse = subscriptionTypes.find(type => type.id === userSubscriptionTypeId)?.allowedCourses?.[0];
		const price = mainSubTypeEIDItems?.data["price"] || 0;
		const initialPhysicalBook = activeSubscription?.subTypeEIDItems!.find(
			({ integrationId, parentId }) => parentId === null && integrationId === ExternalIntegrationIds.PBS
		);
		const addOnsSub = activeSubscription?.subTypeEIDItems!.filter(
			item => item.parentId !== null && item.integrationId === ExternalIntegrationIds.Stripe
		);

		const _addOnsSub: Array<{ name: string; price: number }> = addOnsSub.reduce((subscriptions, addon) => {
			// Replace where filter mapped to
			const doesUserSubscriptionTypeIncludeItem = userSubscriptionTypeAddonIds?.includes(addon.id);
			if (doesUserSubscriptionTypeIncludeItem) {
				// Replace where new object mapped to
				const mappedAddon = {
					name: addon.data.name,
					price: formatUSD(addon.data["price"] || 0)
				};
				subscriptions.push(mappedAddon);
			}
			return subscriptions;
		}, []);

		const shippingPlan = addOnsSub.length + !!initialPhysicalBook > 1 ? multipleShippingPlan : selectedShippingPlan;

		setSignupSuccessUserData({
			email: response["payload"]["email"],
			fullName: `${response["payload"]["firstName"]} ${response["payload"]["lastName"]}`,
			courseName: selectedCourse?.name,
			price: formatUSD(price),
			shippingApplicable,
			shipping: formatUSD(shippingPlan?.data["price"] || 0),
			discount: validatedCoupon?.discountedAmount ? formatUSD(validatedCoupon?.discountedAmount || 0) : null,
			...(_addOnsSub.length && {
				addOnsSub: _addOnsSub
			})
		});
	};

	const handleSubmit = (values: typeof initialFormValues) => {
		dispatch(setIsLoading(true));
		return handleGenerateCaptchaToken()
			.then(token => {
				const couponCode = validatedCoupon && validatedCoupon.coupon.code;
				dispatch(
					signUp({
						CardElement,
						elements,
						stripe,
						values: { startDate, ...values },
						recaptchaGoogle: token,
						couponCode
					})
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				) // @ts-ignore
					.then(r => {
						if (r.payload?.errorMessage === "Your card has insufficient funds.") {
							const {
								userPaymentAndDiscount: { paymentAndDiscountId, id },
								errorMessage: error
							} = r.payload;

							dispatch(
								validateCoupon({
									paymentAndDiscountId: paymentAndDiscountId,
									countryId: parseInt(values.countryId, 10),
									userSubscriptionTypeId: activeSubscription.id
								})
								// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							) // @ts-ignore
								.then(r => {
									setOffer({ discountAmount: r?.payload.paymentAndDiscount.data.amount, discountId: id });
									dispatch(setError(error));
									setActiveStep(Steps.SIGNUP_ERROR);
								});

							return;
						}

						if (r.error) {
							dispatch(setError(r.payload));
							setActiveStep(Steps.SIGNUP_ERROR);
						} else {
							analytics({ eventName: "sign_up_email_finish" });
							setSignUpSuccessUser(r);
							setActiveStep(Steps.SIGNUP_SUCCESS);
							if (callBackUrl) {
								window.location.replace(callBackUrl);
							}
						}
					});
			})
			.catch(() => dispatch(setIsLoading(false)));
	};

	const handleBack = () => {
		setActiveStep(p => (p >= 1 ? p - 1 : 0));
	};

	const handleNext = (values: typeof initialFormValues, isTrial?: boolean) => {
		if (isTrial) handleSubmit(values);
		else {
			if (activeStep === Steps.ACCOUNT) {
				dispatch(setIsLoading(true));
				const { email } = values;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				dispatch(validateDuplicateEmail(email)).then(r => {
					dispatch(setIsLoading(false));
					if (r.error) {
						dispatch(setError(r.payload));
						return;
					}
					dispatch(setError(""));
					localStorage.setItem("analyticsId", `${email}-normal`);
					analytics({ eventName: "sign_up_email_start" });
					setActiveStep(prevActiveStep => (prevActiveStep <= 1 ? prevActiveStep + 1 : 2));
				});
			} else {
				setActiveStep(prevActiveStep => (prevActiveStep <= 1 ? prevActiveStep + 1 : 2));
				if (activeStep === Steps.PAYMENT) {
					handleSubmit(values);
				}
			}
		}
	};

	return (
		<SignUpLayout>
			{activeStep !== Steps.SIGNUP_SUCCESS && (
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={5} pb={6} mt={5}>
					<Box className={classes.cardsContainer}>
						<Card className={classes.card}>
							<Box>
								<Box px={7} pt={2} className={classes.stepperForm}>
									{(activeStep === Steps.SHIPPING || activeStep === Steps.PAYMENT) &&
										selectedCountryId &&
										selectedCountryId !== USA_COUNTRY_ID && (
											<Alert style={{ margin: "16px 0" }} severity="info">
												Payment for custom fees may be required at the time of delivery.
											</Alert>
										)}

									<Stepper activeStep={activeStep} steps={steps} />
									{isIncognito && (
										<Alert
											style={{ margin: "16px 0" }}
											severity="error"
											title="Please disable your browser's incognito mode to continue."
										>
											Sign up is not supported in incognito mode!
										</Alert>
									)}
									<Formik
										initialValues={{
											...initialFormValues
										}}
										validationSchema={validationSchema}
										validateOnChange
										validateOnBlur={false}
										onSubmit={values => {
											handleSubmit(values);
										}}
									>
										{({
											isValid,
											values,
											setErrors,
											setFieldValue,
											setTouched,
											setFieldTouched,
											handleChange,
											dirty,
											errors,
											resetForm
										}) => {
											let valid = isValid && isEmpty(errors) && dirty;
											if (!dirty && activeStep !== Steps.SIGNUP_ERROR) {
												valid = validateFormValues(signUpFormKeys[activeStep], values);
											}
											const displayOffer = offer && errorMessage === "Your card has insufficient funds.";
											return (
												<>
													<Form>
														{activeStep === Steps.ACCOUNT && (
															<AccountFormDetails
																setFieldValue={setFieldValue}
																termValue={values.terms}
																confirmPassword
															/>
														)}
														{activeStep === Steps.SHIPPING && (
															<ShippingForm setTouchedField={setFieldTouched} handleChanged={handleChange} />
														)}
														{activeStep === Steps.PAYMENT && (
															<PaymentForm
																paymentSource={paymentSource}
																setFieldValue={setFieldValue}
																setTouched={setTouched}
																termValue={values.terms}
																showTerms
																setTouchedField={setFieldTouched}
																handleChanged={handleChange}
																startDate={startDate}
																setStartDate={setStartDate}
															/>
														)}
														{activeStep === Steps.SIGNUP_ERROR && (
															<GuestSignUpError errorMessage={errorMessage} tryAgainEnabled={tryAgainEnabled} />
														)}
													</Form>
													{errorMessage &&
														(displayOffer ? (
															<LackOfFundsDiscount />
														) : (
															<Box mt={3}>
																<Typography variant="caption" style={{ color: "red" }}>
																	{errorMessage}
																</Typography>
															</Box>
														))}

													{activeStep !== Steps.SIGNUP_ERROR ? (
														<FormActions
															back={() => {
																if (activeStep == Steps.PAYMENT) {
																	// Make sure to reset the formik state, CardElement is not a formik field
																	setFieldValue("validCardDetails", false).then(() => setErrors({}));
																} else {
																	setErrors({});
																}
																handleBack();
															}}
															next={isTrial => {
																resetForm({ values }); // resets dirty fields
																handleNext(values, isTrial);
															}}
															valid={valid}
															disabled={!isValid && !activeSubscription}
															loading={isLoading}
															step={activeStep}
														/>
													) : activeStep === Steps.SIGNUP_ERROR && tryAgainEnabled ? (
														<Box display="flex" justifyContent="center" mt={6}>
															<Button
																color="primary"
																variant="filled"
																onClick={() => {
																	dispatch(setError(""));
																	offer && setFieldValue("userPaymentAndDiscountId", offer.discountId);
																	setActiveStep(Steps.PAYMENT);
																}}
															>
																{displayOffer ? "Try Again With Discount" : "Try Again"}
															</Button>
														</Box>
													) : null}
												</>
											);
										}}
									</Formik>
								</Box>
							</Box>
						</Card>
						<Summary
							setFreeTrial={setFreeTrial}
							courses={courses}
							startDate={startDate}
							setStartDate={setStartDate}
							showCrossButton={false}
							specialOfferDiscount={offer?.discountAmount}
						/>
					</Box>
				</Box>
			)}
			{activeStep === Steps.SIGNUP_SUCCESS && (
				<Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
					<Box className={classes.successCardsContainer}>
						<Card className={classes.cardLeft}>
							<SignUpSuccessLeft signupSuccessUserData={signupSuccessUserData} trial={false} />
						</Card>
						<Card className={classes.cardRight}>
							<SignUpSuccessRight />
						</Card>
					</Box>
				</Box>
			)}
		</SignUpLayout>
	);
};

export default PaidSignUp;
