import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Box, Breadcrumbs, Popper, Typography } from "@material-ui/core";
import {
	ChevronLeft,
	ExpandLess,
	ExpandMore,
	Lock,
	NavigateNext,
	PlayCircleFilled,
	Visibility
} from "@material-ui/icons";
import { ReactComponent as DiscountIcon } from "@remar/shared/dist/assets/icons/icon-coupon-multicolour-light.svg";
import { ReactComponent as DownloadIconSvg } from "@remar/shared/dist/assets/icons/icon-download.svg";
import Button from "@remar/shared/dist/components/Button/Button";

import { FilesUploaded } from "@remar/shared/dist/components/FilesUploaded/FilesUploaded";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { Course, CourseChapterSection, CourseChapterSectionLesson } from "@remar/shared/dist/models";
import { downloadURI } from "@remar/shared/dist/utils/serviceUtils/helpers";
import { getCurrentSubscription } from "@remar/shared/dist/utils/subscriptionUtils";

import { minutesToSeconds } from "date-fns";
import useAnalyticsEventTracker from "hooks/googleAnalytics";

import findLast from "lodash/findLast";

import { Link, useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import {
	applyValidatedCoupon,
	getUserData,
	selectCurrentlyAllowedFullCourses,
	selectCurrentlyAllowedTrialCourses,
	selectInactiveSubscription,
	selectSubscriptions,
	setUpgradeModal
} from "store/features/Auth/authSlice";
import {
	fetchIntroLessons,
	getCourseCompletion,
	loadTrialCourse,
	resetSelectedLesson,
	selectCourseInfoForSelectedLesson,
	selectFullState,
	selectTrialCoursesInfo,
	setSelectedLesson
} from "store/features/Course/courseSlice";
import { clearLessonState, endUserActivityTracking } from "store/features/Lesson/lesson.slice";
import { selectSubscriptionInfo } from "store/features/MyAccount/myAccountSlice";
import { getFullState as getFullNotificationsState } from "store/features/notifications/notifications.slice";

import SwiperCore, { Mousewheel, Navigation } from "swiper/core";

import { Swiper, SwiperSlide } from "swiper/react";

import { LessonTypes, routes } from "core/constants";

import ResumeAndRenewSubscriptionButton from "modules/MyAccount/ResumeAndRenewSubscriptionButton";

import Comments from "./Comments";
import CompletionCertificate from "./CompletionCertificate";
import QuizLesson from "./QuizLesson";
import { Trailer } from "./Trailer";
import { VITLesson } from "./VITLesson";
import VideoLesson from "./VideoLesson";

import {
	BreadcrumbsContainer,
	ContainTest,
	Description,
	DiscussionBoardContainer,
	DownloadsContainer,
	IconContainer,
	InfoHeader,
	InfoText,
	LessonContainer,
	LessonInfoContainer,
	LessonInfoRightSide,
	LessonMaterials,
	LessonMediaContainer,
	LessonName,
	LessonNumber,
	LessonPreviewContainer,
	LessonPreviewInfo,
	LessonVideoContainer,
	LessonWrapper,
	LessonsListContainer,
	LessonsMaterialDropdown,
	LockIcon,
	SelectCherron,
	useStyles
} from "./style";
import "swiper/swiper.min.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Mousewheel, Navigation]);

interface NavOptions {
	prevEl: HTMLDivElement | null;
	nextEl: HTMLDivElement | null;
}
const LessonView = () => {
	const analytics = useAnalyticsEventTracker("Lesson");
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const history = useHistory();
	const { id: strLessonId } = useParams<{ id: string }>();
	const { sectionId: strSectionId } = useParams<{ sectionId: string }>();
	const lessonId = parseInt(strLessonId);
	const sectionId = parseInt(strSectionId, 10);

	const nextEl = useRef<HTMLDivElement | null>(null);
	const prevEl = useRef<HTMLDivElement | null>(null);

	const [isQuizExit, setIsQuizExit] = useState(false);
	const [allLessons, setAllLessons] = useState<CourseChapterSectionLesson[]>([]);
	const [currentSection, setCurrentSection] = useState<CourseChapterSection | null>(null);
	const [currentSectionLesson, setCurrentSectionLesson] = useState<CourseChapterSectionLesson | null>(null);
	const [isCurrentSectionLessonLoading, setIsCurrentSectionLesson] = useState<boolean>(true);
	const [nextLesson, setNextLesson] = useState<CourseChapterSectionLesson | undefined>(undefined);
	const [showCompletionCertificate, setShowCompletionCertificate] = useState(false);
	const [materialsOpen, setMaterialsOpen] = useState(false);

	const { introLessons, selectedIntroLessonIndex, selectedSectionLesson } = useAppSelector(selectFullState);
	const { trialCourses, isLoading }: { trialCourses: Course[]; isLoading: boolean } =
		useAppSelector(selectTrialCoursesInfo);
	const currentlyAllowedTrialCourses = useAppSelector(selectCurrentlyAllowedTrialCourses);
	const currentlyAllowedFullCourses = useAppSelector(selectCurrentlyAllowedFullCourses);
	const subscriptions = useAppSelector(selectSubscriptions);
	const { course, sectionLesson: sectionLessonFromCourse } = useAppSelector(selectCourseInfoForSelectedLesson);
	const subscriptionInfo = useAppSelector(selectSubscriptionInfo);
	const { isRenewEnabled } = useAppSelector(selectInactiveSubscription);
	const { bannerData } = useAppSelector(getFullNotificationsState);

	const isTrial = useMemo(() => getCurrentSubscription(subscriptions).type?.isTrial, [subscriptions]);
	const fetchCourses = () => {
		if (currentlyAllowedTrialCourses || currentlyAllowedFullCourses) {
			const courses = [...currentlyAllowedTrialCourses!, ...currentlyAllowedFullCourses!];
			const ids = courses.map(e => e.id);
			const trial = isTrial === true ? isTrial : !!currentlyAllowedTrialCourses?.length;
			dispatch(loadTrialCourse({ ids, isTrial: trial, includeLessonDetails: true }));
		}
	};

	const fetchCourseCompletion = () => {
		dispatch(getCourseCompletion());
	};

	useEffect(() => {
		// end lesson tracking activity
		const stopActivityTracking = () => {
			dispatch(endUserActivityTracking());
		};
		window.addEventListener("beforeunload", stopActivityTracking);
		return () => {
			dispatch(endUserActivityTracking());
			window.removeEventListener("beforeunload", stopActivityTracking);
		};
	}, [dispatch]);

	useEffect(() => {
		return () => {
			dispatch(resetSelectedLesson());
		};
	}, [dispatch]);

	useEffect(() => {
		fetchCourses();
	}, [currentlyAllowedTrialCourses, currentlyAllowedFullCourses]);

	useEffect(() => {
		if (!isLoading) {
			dispatch(setSelectedLesson(lessonId));
		}
	}, [trialCourses, isLoading, lessonId, dispatch]);

	useEffect(() => {
		if (!sectionId && lessonId && selectedIntroLessonIndex === null) {
			dispatch(fetchIntroLessons());
		}
	}, [sectionId, lessonId]);

	useEffect(() => {
		if (!course) {
			setAllLessons([]);
			setCurrentSection(null);
			// setCurrentSectionLesson(null);
			setNextLesson(undefined);
			return;
		}
		const sections = course
			.chapters!.reduce<CourseChapterSection[]>((acc, item) => {
				if (!item.lockedChapter) {
					acc.push(...item.sections!);
				}
				return acc;
			}, [])
			.flat();

		const _allLessons = sections.flatMap(({ sectionLessons, chapterId }) =>
			sectionLessons!.map(lesson => ({ ...lesson, chapterId }))
		);
		const _nextLesson = _allLessons[_allLessons.findIndex(item => item!.id === sectionLessonFromCourse!.id) + 1];
		setAllLessons(_allLessons);
		setCurrentSection(sections.find(f => f.id === sectionId)!);
		setCurrentSectionLesson(sectionLessonFromCourse);
		setNextLesson(_nextLesson);
		setIsCurrentSectionLesson(false);
	}, [selectedSectionLesson, course, sectionLessonFromCourse, sectionId]);

	useEffect(() => {
		if (selectedIntroLessonIndex === null) {
			return;
		}
		const lesson = introLessons[selectedIntroLessonIndex];
		setAllLessons([]);
		setCurrentSection(null);
		setCurrentSectionLesson({
			id: 0,
			isAvailableForTrial: true,
			isLockedByPayment: false,
			isLockedForCurrentUser: false,
			lesson,
			lessonId: lesson.id,
			order: 0,
			sectionId: 0
		});
		setNextLesson(undefined);
	}, [introLessons, selectedIntroLessonIndex]);
	const lessonMaterialAnchor = useRef(null);

	const [loadingError, setLoadingError] = useState(false);

	useEffect(() => {
		isQuizExit && history.push(`${routes.course.getPath()}/?chapterId=${currentSection!.chapterId}`);
		setIsQuizExit(false);
	}, [isQuizExit]);

	useEffect(() => {
		setMaterialsOpen(false);
	}, [currentSectionLesson?.lessonId]);
	const { isLockedByPayment, isLockedForCurrentUser, lesson } = currentSectionLesson || {};

	const imageUrl =
		lesson?.interactiveBlocks && lesson?.interactiveBlocks.length && lesson?.interactiveBlocks[0].video!.thumbnailUrl;

	useEffect(() => {
		setLoadingError(false);
	}, [imageUrl]);

	const handleNextLesson = useCallback(() => {
		if (course?.isCourseComplete && !course?.certificateAlreadyDisplayed) {
			setShowCompletionCertificate(true);
			return;
		}
		if (nextLesson.lesson?.typeId !== LessonTypes.TestOnly) {
			fetchCourses();
		}
		history.push(`${routes.lesson.getPath()}/${nextLesson.lessonId}/${nextLesson.sectionId}`);
	}, [course, history, nextLesson]);

	const Loader = <ContentLoader />;

	if (!currentSectionLesson && !isCurrentSectionLessonLoading) {
		return <>Lesson not found</>;
	}

	if (showCompletionCertificate && !!currentlyAllowedFullCourses?.length && !isTrial) {
		return <CompletionCertificate course={course!.name} />;
	}

	const handleChange = e => {
		e.preventDefault();
	};

	const handlePreviousLesson = () => {
		const prevLesson =
			findLast(
				allLessons,
				({ isLockedForCurrentUser, chapterId }) => !isLockedForCurrentUser && currentSection!.chapterId === chapterId
			) || findLast(allLessons, ({ isLockedForCurrentUser }) => !isLockedForCurrentUser);
		history.push(`${routes.lesson.getPath()}/${prevLesson!.lessonId}/${prevLesson!.sectionId}`);
	};

	const hideAutoplay = !nextLesson && !course?.isCourseComplete;
	const videoData =
		(lesson?.interactiveBlocks && lesson?.interactiveBlocks.length && lesson?.interactiveBlocks[0].video) ||
		lesson?.video;
	const videoUrl = videoData?.videoUrl || "";
	const subtitleUrl = videoData?.subtitleUrl || "";
	const minutes = (lesson?.manualTimeLengthComponent ?? lesson?.totalTimeLengthInMinutes) || 0;
	const seconds = minutes < 60 ? minutesToSeconds(minutes) : 0;

	const containsTest = lesson?.typeId === LessonTypes.Interactive || lesson?.typeId === LessonTypes.TestOnly;
	const containsTestLabel = lesson?.typeId === LessonTypes.TestOnly ? "Contains test" : "Interactive Training";

	return (
		<LessonWrapper
			onCut={handleChange}
			onCopy={handleChange}
			onPaste={handleChange}
			onSelect={handleChange}
			onContextMenu={handleChange}
		>
			{!isLoading && (
				<div>
					{lesson && lesson.isIntro ? (
						<Box display="flex" mt={3}></Box>
					) : (
						<Box>
							<Typography className={classes.title}>{lesson?.name}</Typography>
						</Box>
					)}
					{course ? (
						<LessonInfoContainer>
							<BreadcrumbsContainer>
								<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
									<Link to={"#"} onClick={() => setIsQuizExit(true)}>
										<Typography className={classes.breadcrumb}>Courses</Typography>
									</Link>
									<Typography className={classes.breadcrumbActive}>{course.name}</Typography>
								</Breadcrumbs>
							</BreadcrumbsContainer>
							<LessonInfoRightSide>
								<LessonsListContainer>
									<Swiper
										navigation={{
											prevEl: prevEl.current,
											nextEl: nextEl.current
										}}
										slidesPerView="auto"
										spaceBetween={16}
										observer={true}
										observeParents={true}
										mousewheel={{ releaseOnEdges: true }}
										virtualTranslate
										onSetTranslate={(swiper, delta) => {
											if (swiper.$el[0].offsetWidth < swiper.virtualSize) {
												swiper.$wrapperEl.css("margin-left", delta + "px");
											}
										}}
										onAfterInit={swiper => {
											swiper.$el.css("margin", "0 18px 0 25px");
											swiper.update();
										}}
										onBeforeInit={swiper => {
											if (swiper?.params?.navigation) {
												(swiper.params.navigation as NavOptions).prevEl = prevEl.current;
												(swiper.params.navigation as NavOptions).nextEl = nextEl.current;
											}
										}}
									>
										{currentSection?.sectionLessons?.map(({ isLockedForCurrentUser, lesson }, index) =>
											lesson?.id === lessonId ? (
												<SwiperSlide key={`swiper-slide-${index}`}>
													<LessonMaterials
														onClick={() => setMaterialsOpen(materialsOpen => !materialsOpen)}
														ref={lessonMaterialAnchor}
													>
														<LessonNumber current>
															<span>{index + 1}</span>
														</LessonNumber>
														<LessonName>{lesson?.name}</LessonName>
														<IconContainer>
															<SelectCherron open={materialsOpen} />
														</IconContainer>
													</LessonMaterials>
												</SwiperSlide>
											) : (
												<SwiperSlide key={`swiper-slide-${index}`}>
													<LessonNumber
														key={`lesson-index-${index}`}
														onClick={() => history.push(`${routes.lesson.getPath()}/${lesson?.id}/${sectionId}`)}
													>
														<span>{index + 1}</span>
														{isLockedForCurrentUser && <LockIcon />}
													</LessonNumber>
												</SwiperSlide>
											)
										)}
									</Swiper>
									<div className="swiper-button-prev" ref={prevEl}>
										<SelectCherron />
									</div>
									<div className="swiper-button-next" ref={nextEl}>
										<SelectCherron />
									</div>
								</LessonsListContainer>
								<Button
									variant="filled"
									color="secondary"
									size={"large"}
									onClick={() => {
										setIsQuizExit(true);
									}}
								>
									Back
								</Button>
							</LessonInfoRightSide>
							<Popper id="lesson-materials" open={materialsOpen} anchorEl={lessonMaterialAnchor.current}>
								<LessonsMaterialDropdown onClick={e => e.stopPropagation()}>
									{lesson?.description}
									{containsTest && (
										<ContainTest>
											<Description />
											{containsTestLabel}
										</ContainTest>
									)}
									{lesson?.attachments?.length > 0 && (
										<DownloadsContainer>
											<FilesUploaded
												attachments={lesson?.attachments ?? []}
												IconSvg={DownloadIconSvg}
												onClick={attachment => {
													analytics({
														eventName: "downloaded_lesson_materials",
														eventIdentifier: attachment.name
													});

													downloadURI(attachment.fileUrl, attachment.name);
												}}
												isDownload
											/>
										</DownloadsContainer>
									)}
								</LessonsMaterialDropdown>
							</Popper>
						</LessonInfoContainer>
					) : lesson && lesson.isIntro ? (
						<Box display="flex" mt={1} mb={2}>
							<Breadcrumbs separator={<NavigateNext fontSize="small" />}>
								<Link to={"#"} onClick={() => history.push(`${routes.help.getPath()}`)}>
									<Typography className={classes.breadcrumb}>Help</Typography>
								</Link>
								<Typography className={classes.breadcrumbActive}>Intro VIT</Typography>
							</Breadcrumbs>
							<Box display="flex" ml="auto" mr="3%">
								<Button variant="filled" color="secondary" onClick={() => history.push(`${routes.help.getPath()}`)}>
									Back
								</Button>
							</Box>
						</Box>
					) : (
						<Box display="flex" mt={1} mb={2}></Box>
					)}
				</div>
			)}

			<LessonContainer>
				<LessonVideoContainer
					display="flex"
					flexDirection="row"
					flexWrap="wrap"
					flex="100%"
					position="relative"
					alignItems="center"
					justifyContent="center"
					className={!isLockedForCurrentUser ? classes.playableLesson : ""}
					onClick={() => {
						if (!isLockedForCurrentUser) {
							// TODO: play lesson here
						}
					}}
				>
					{isLoading ? (
						Loader
					) : (
						<>
							{isLockedForCurrentUser && lesson?.trailer?.videoUrl ? (
								<Trailer
									url={lesson.trailer.videoUrl}
									sectionLesson={currentSectionLesson}
									allLessons={allLessons}
									isTrial={isTrial}
								/>
							) : isLockedForCurrentUser && !lesson?.trailer ? (
								<LessonPreviewContainer>
									{!loadingError && !!imageUrl && (
										<img
											alt="preview"
											src={imageUrl}
											className={classes.lessonImage}
											onError={() => setLoadingError(true)}
										/>
									)}
									<LessonPreviewInfo>
										<Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
											<LockIcon />
											<InfoHeader>Lesson Locked</InfoHeader>
											<InfoText>
												{`To unlock this lesson, ${
													isLockedByPayment
														? !isTrial
															? "you need to start your subscription"
															: "you need to upgrade your subscription"
														: "you need to complete the previous unlocked lesson"
												} `}
											</InfoText>
											{isLockedByPayment ? (
												<>
													{isTrial && !isRenewEnabled && (
														<Button
															onClick={() => {
																if (bannerData?.coupon) {
																	dispatch(applyValidatedCoupon(bannerData?.coupon));
																}
																dispatch(setUpgradeModal(true));
															}}
															variant="filled"
															color="primary"
														>
															{!!bannerData?.coupon && (
																<Box display={"flex"} mr={1}>
																	<DiscountIcon />
																</Box>
															)}
															Upgrade Subscription
														</Button>
													)}
													{(subscriptionInfo?.subscription.isCancelled || isRenewEnabled) && isTrial && (
														<ResumeAndRenewSubscriptionButton
															id={subscriptionInfo?.subscription?.id as number}
															sideEffect={() => {
																dispatch(getUserData());
																history.push(`${routes.course.getPath()}?refetch=true`);
															}}
														/>
													)}
												</>
											) : isLockedForCurrentUser ? (
												<Button onClick={handlePreviousLesson} variant="filled" color="primary">
													<ChevronLeft /> Complete Previous Lesson
												</Button>
											) : null}
										</Box>
									</LessonPreviewInfo>
								</LessonPreviewContainer>
							) : (
								(lesson?.typeId === LessonTypes.VideoOnly ? (
									<VideoLesson
										url={videoUrl}
										subtitleUrl={subtitleUrl}
										sectionLessonId={currentSectionLesson!.id}
										courseChapterId={currentSection!.chapterId}
										sideEffect={fetchCourseCompletion}
										handleNextLesson={handleNextLesson}
										setShowCompletionCertificate={setShowCompletionCertificate}
										certificateAlreadyDisplayed={course?.certificateAlreadyDisplayed}
										hideAutoplay={hideAutoplay}
										hasNextLesson={nextLesson!}
									/>
								) : lesson?.typeId === LessonTypes.TestOnly ? (
									<QuizLesson
										lesson={lesson}
										sectionLessonId={currentSectionLesson?.id}
										nextLesson={nextLesson!}
										isQuizExit={isQuizExit}
										sideEffect={fetchCourses}
										handleNextLesson={handleNextLesson}
										isCourseComplete={!!course?.isCourseComplete}
										setShowCompletionCertificate={setShowCompletionCertificate}
										isTrial={isTrial}
										certificateAlreadyDisplayed={course?.certificateAlreadyDisplayed}
									/>
								) : lesson?.typeId === LessonTypes.Interactive ? (
									<VITLesson
										lesson={lesson}
										sideEffect={fetchCourseCompletion}
										sectionLessonId={currentSectionLesson?.id}
										courseChapterId={currentSection?.chapterId}
										handleNextLesson={handleNextLesson}
										setShowCompletionCertificate={setShowCompletionCertificate}
										certificateAlreadyDisplayed={course?.certificateAlreadyDisplayed}
										hasNextLesson={nextLesson!}
									/>
								) : null) || (
									//TODO: will be removed once all the lesson types have component as above for Video Lesson
									<Box className={classes.unlockedInfoBox}>
										<PlayCircleFilled className={classes.infoBoxIcon} />
									</Box>
								)
							)}
						</>
					)}

					{trialCourses.length && lesson && !isLoading && (
						<DiscussionBoardContainer>
							<Comments
								isTrial={isTrial}
								isLockedForCurrentUser={isLockedForCurrentUser}
								isLockedByPayment={isLockedByPayment}
							/>
						</DiscussionBoardContainer>
					)}
				</LessonVideoContainer>
				<LessonMediaContainer display="flex" flexDirection="column" width="40%" minWidth="340">
					{currentSection &&
						currentSection.sectionLessons!.map((slItem, slIndex) => {
							const { lesson: slItemLesson } = slItem;
							const isCurrentLesson = slItemLesson!.id === lessonId;
							const containsTest =
								slItemLesson!.typeId === LessonTypes.Interactive || slItemLesson!.typeId === LessonTypes.TestOnly;
							const hasDetails = slItemLesson!.totalTimeLengthInMinutes || containsTest;
							return (
								<Box
									display="flex"
									flexDirection="row"
									key={`section-lesson-${slIndex}`}
									className={`${classes.lessonSection} ${!isCurrentLesson ? classes.expandableLesson : ""}`}
									onClick={() => {
										if (!isCurrentLesson) {
											dispatch(clearLessonState());
											history.push(`${routes.lesson.getPath()}/${slItemLesson!.id}/${sectionId}`);
										}
									}}
								>
									<Box display="flex" flexDirection="column" width="100%">
										<Box
											className={`${classes.lessonSectionTitle} ${
												isCurrentLesson ? classes.lessonSectionTitleSelected : ""
											}`}
										>
											<Box className={classes.lessonSectionIndex}>{slIndex + 1}</Box>
											<Box>{slItemLesson!.name}</Box>
											<Box ml="10px">
												{slItem.isLockedForCurrentUser ? <Lock className={classes.lessonSectionLocked}></Lock> : <></>}
											</Box>
											<Box className={classes.lessonSectionControlContainer}>
												{isCurrentLesson ? (
													<ExpandLess className={classes.lessonSectionControl} />
												) : (
													<ExpandMore className={classes.lessonSectionControl} />
												)}
											</Box>
										</Box>
										{isCurrentLesson ? (
											<>
												<Box className={classes.lessonDescription}>
													{slItem.isLockedForCurrentUser && <div className={classes.lessonLocked}>Locked Lesson</div>}
													<div>
														{!slItemLesson!.description || slItemLesson!.description === "null"
															? `${slItemLesson!.name} lesson.`
															: slItemLesson!.description}
													</div>
												</Box>
												{hasDetails ? (
													<Box className={classes.lessonDetails}>
														{slItemLesson!.totalTimeLengthInMinutes || slItemLesson!.manualTimeLengthComponent ? (
															<>
																<Box display="flex" mr="5px">
																	<Visibility></Visibility>
																</Box>
																<Box display="flex" mr="5px">
																	{minutes}
																	min
																</Box>
																<Box display="flex" mr="20px">
																	{seconds}
																	sec
																</Box>
															</>
														) : (
															<></>
														)}
														{containsTest ? (
															<>
																<Box display="flex" mr="5px">
																	<Description></Description>
																</Box>
																<Box display="flex">{containsTestLabel}</Box>
															</>
														) : (
															<></>
														)}
													</Box>
												) : (
													<></>
												)}
												{!isLockedByPayment && (
													<Box className={classes.lessonAttachmentsItemRow}>
														{slItemLesson!.attachments && slItemLesson!.attachments.length != 0 && (
															<FilesUploaded
																attachments={slItemLesson!.attachments}
																IconSvg={DownloadIconSvg}
																onClick={attachment => {
																	analytics({
																		eventName: "downloaded_lesson_materials",
																		eventIdentifier: attachment.name
																	});

																	downloadURI(attachment.fileUrl, attachment.name);
																}}
																isDownload
															/>
														)}
													</Box>
												)}
											</>
										) : (
											<></>
										)}
									</Box>
								</Box>
							);
						})}
				</LessonMediaContainer>
			</LessonContainer>
		</LessonWrapper>
	);
};

export default LessonView;
