import React, { useEffect, useMemo, useState } from "react";

import { Box, Container, IconButton, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Alert } from "@material-ui/lab";
import Button from "@remar/shared/dist/components/Button";
import LogoComponent from "@remar/shared/dist/components/LogoComponent";
import Stepper from "@remar/shared/dist/components/Stepper";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { LocationEnum } from "@remar/shared/dist/services/subjectCategories/modal";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { Form, Formik } from "formik";

import isEmpty from "lodash/isEmpty";

import ReactPixel from "react-facebook-pixel";

import { useAppDispatch, useAppSelector } from "store";
import {
	getFullState as getAuthFullState,
	getCourse,
	selectCourse,
	selectLoadingCourse,
	setError,
	setSelectedCountry
} from "store/features/Auth/authSlice";
import { USA_COUNTRY_ID } from "store/features/Auth/constants";
import { getFullState, getPaymentDetails } from "store/features/Billing/BillingSlice";
import { INSUFFICIENT_FUNDS, selectFullState, selectSubscriptionInfo } from "store/features/MyAccount/myAccountSlice";

import FormActions from "modules/Auth/components/FormActions";
import { PaymentForm, ShippingForm } from "modules/Auth/components/Forms";
import { GuestSignUpError as UpgradeError } from "modules/Auth/components/GuestSignUpError";
import Summary from "modules/Auth/components/Summary";

import { upgradeFormKeys, validateFormValues } from "modules/Auth/components/utils";
import PaymentMethods from "modules/Billings/PaymentMethods";

import { PixelEvent, setTiktokEvent } from "modules/utils";

import { UpgradeSubscriptionCard, useStyles } from "./styles";

import SelectOfferings from "../Auth/components/SelectOfferings";

const steps = [{ label: "Course" }, { label: "Shipping" }, { label: "Payment" }];
export enum Steps {
	OFFER,
	SHIPPING,
	PAYMENT,
	ERROR
}
const UpgradeSubscription = ({
	activeStep,
	setActiveStep,
	initialFormValues,
	validationSchema,
	handleSubmit,
	error,
	subcriptionIsLoading,
	handleBack,
	handleNext,
	initStep,
	disabled = false,
	closeModal
}) => {
	const theme = useTheme<IExtendedTheme>();
	const classes = useStyles();
	const dispatch = useAppDispatch();
	const isMobileAndTablet = useMediaQuery(theme.breakpoints.down("sm"));

	const { userInfo } = useAppSelector(selectFullState);
	const { user, selectedCountryId } = useAppSelector(getAuthFullState);
	const subscriptionInfo = useAppSelector(selectSubscriptionInfo);
	const course = useAppSelector(selectCourse);
	const loadingCourse = useAppSelector(selectLoadingCourse);
	const { paymentMethods } = useAppSelector(getFullState);

	const [showPrefilledInfo, setShowPrefilledInfo] = useState(false);
	const [showCardPrefilledInfo, setShowCardPrefilledInfo] = useState(false);
	const [prefilledValues, setPrefilledValues] = useState({});
	const [modal, setModal] = useState(false);
	const [startDate, setStartDate] = useState(new Date());

	const values = useMemo(
		() => ({
			address1: userInfo?.shippingDetails?.address1,
			address2: userInfo?.shippingDetails?.address2,
			city: userInfo?.shippingDetails?.city,
			phoneNumber: userInfo?.shippingDetails?.phoneNumber,
			state: userInfo?.shippingDetails?.state,
			zip: userInfo?.shippingDetails?.zipCode,
			countryId: userInfo?.shippingDetails?.countryId
		}),
		[userInfo?.shippingDetails]
	);

	const courseId = useMemo(() => subscriptionInfo?.subscription?.type?.allowedCourses[0]?.id, [subscriptionInfo]);
	const isInstitutionalStudent = useMemo(
		() =>
			!!user?.allowedLocations?.length &&
			user?.allowedLocations.some(l => l.id !== LocationEnum.Main && l.id !== LocationEnum.Common),
		[user]
	);

	useEffect(() => {
		courseId && !course && dispatch(getCourse({ id: courseId, isInstitutionalStudent }));
	}, [dispatch, courseId, isInstitutionalStudent, course]);

	useEffect(() => {
		if (!!userInfo?.shippingDetails) {
			setShowPrefilledInfo(true);
			showPrefilledInfo && setPrefilledValues({ ...values });
			showPrefilledInfo && dispatch(setSelectedCountry(values.countryId!));
		}
		if (paymentMethods?.hasOwnProperty("brand")) {
			setShowCardPrefilledInfo(true);
		}
	}, [dispatch, paymentMethods, showPrefilledInfo, userInfo?.shippingDetails, values]);

	useEffect(() => {
		if (activeStep === Steps.SHIPPING) {
			setTiktokEvent(PixelEvent.InitiateCheckout);
			ReactPixel.track(PixelEvent.InitiateCheckout);
		}
		if (activeStep === Steps.PAYMENT) {
			setTiktokEvent(PixelEvent.AddPaymentInfo);
			ReactPixel.track(PixelEvent.AddPaymentInfo);
		}

		if (!!userInfo?.shippingDetails && !paymentMethods?.hasOwnProperty("brand")) {
			dispatch(getPaymentDetails());
		}
	}, [activeStep, dispatch, paymentMethods, userInfo?.shippingDetails]);

	useEffect(() => {
		if (!!error) {
			setActiveStep(Steps.ERROR);
		}
	}, [error, setActiveStep]);

	const displayOffer = useMemo(() => error === INSUFFICIENT_FUNDS, [error]);
	const OfferStep = (
		<Box className={classes.selectOfferContainer}>
			<Box>
				<LogoComponent />
				<Box display="flex" alignItems="center" justifyContent={"center"} style={{ position: "relative" }}>
					<Typography variant={"h5"}>Select Course</Typography>
					<IconButton onClick={closeModal} style={{ position: "absolute", right: "10px", top: "-40px" }}>
						<CloseIcon color="disabled" />
					</IconButton>
				</Box>
			</Box>
			<UpgradeSubscriptionCard>
				{course && (
					<SelectOfferings
						course={course}
						isSigningUp={false}
						isGetRecurring={false}
						nextStep={() => setActiveStep(s => s + 1)}
						onClose={closeModal}
					/>
				)}
			</UpgradeSubscriptionCard>
		</Box>
	);

	return (
		<Container maxWidth={activeStep === Steps.OFFER ? "lg" : "md"} className={classes.container}>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				height={isMobileAndTablet ? "100%" : "100vh"}
			>
				{loadingCourse ? (
					<UpgradeSubscriptionCard>
						<ContentLoader />
					</UpgradeSubscriptionCard>
				) : activeStep === Steps.OFFER ? (
					OfferStep
				) : (
					<Box className={classes.cardsContainer}>
						<UpgradeSubscriptionCard>
							<Box>
								<Box px={isMobileAndTablet ? 0 : 9} pt={isMobileAndTablet ? 0 : 2}>
									{(activeStep === Steps.SHIPPING || activeStep === Steps.PAYMENT) &&
										selectedCountryId &&
										selectedCountryId !== USA_COUNTRY_ID && (
											<Alert style={{ margin: "16px 0" }} severity="info">
												Payment for custom fees may be required at the time of delivery.
											</Alert>
										)}
									<Stepper activeStep={activeStep} steps={steps} />
									<Formik
										initialValues={showPrefilledInfo ? prefilledValues : initialFormValues}
										validationSchema={validationSchema}
										validateOnChange
										onSubmit={values => {
											handleSubmit({ ...values, startDate });
										}}
									>
										{({
											isValid,
											values,
											touched,
											setFieldValue,
											setTouched,
											setFieldTouched,
											handleChange,
											dirty,
											errors,
											setErrors,
											resetForm
										}) => {
											let valid = isValid && isEmpty(errors) && dirty;
											if (!dirty && activeStep !== Steps.ERROR && activeStep !== Steps.OFFER) {
												const formKey = activeStep - 1;
												valid = validateFormValues(upgradeFormKeys[formKey], values);
											}
											if (activeStep === Steps.PAYMENT && showPrefilledInfo) {
												valid = true;
											}
											return (
												<>
													<Form>
														{activeStep === Steps.SHIPPING && (
															<ShippingForm setTouchedField={setFieldTouched} handleChanged={handleChange} />
														)}
														{activeStep === Steps.PAYMENT && showCardPrefilledInfo ? (
															<>
																<Box mb={2}>
																	<Typography variant="h1" className={classes.filledFormTitle}>
																		Payment
																	</Typography>
																</Box>
																<PaymentMethods
																	showRecurringInfo
																	onEdit={() => {
																		dispatch(setError(""));
																		setModal(true);
																	}}
																	modal={modal}
																	setModal={setModal}
																	showPrefilledInfo={showPrefilledInfo}
																/>
															</>
														) : activeStep === Steps.PAYMENT && !showCardPrefilledInfo ? (
															<PaymentForm
																setFieldValue={setFieldValue}
																touched={touched}
																setTouched={setTouched}
																setTouchedField={setFieldTouched}
																handleChanged={handleChange}
																termValue={values.terms}
																showTerms
																startDate={startDate}
																setStartDate={setStartDate}
															/>
														) : activeStep === Steps.ERROR ? (
															<UpgradeError displayOffer={displayOffer} errorMessage={error} />
														) : (
															<></>
														)}
													</Form>
													{activeStep !== Steps.ERROR ? (
														<FormActions
															back={() => {
																if (activeStep == Steps.PAYMENT) {
																	// Make sure to reset the formik state, CardElement is not a formik field
																	setFieldValue("validCardDetails", false).then(() => setErrors({}));
																} else {
																	setErrors({});
																}
																handleBack();
															}}
															next={() => {
																resetForm({ values }); // resets dirty fields
																handleNext({ ...values, paymentMethodId: paymentMethods?.id, startDate });
															}}
															valid={valid}
															loading={subcriptionIsLoading}
															step={activeStep}
															disabled={disabled}
															firstStep={initStep || Steps.OFFER}
															lastStep={Steps.PAYMENT}
															upgradeSubscription={true}
														/>
													) : activeStep === Steps.ERROR ? (
														<Box display="flex" justifyContent="center" mt={6}>
															<Button
																color="primary"
																variant="filled"
																onClick={() => {
																	dispatch(setError(""));
																	setActiveStep(Steps.PAYMENT);
																}}
															>
																{displayOffer ? "Try Again With Discount" : "Try Again"}
															</Button>
														</Box>
													) : null}
												</>
											);
										}}
									</Formik>
								</Box>
							</Box>
						</UpgradeSubscriptionCard>
						<Summary
							startDate={startDate}
							setStartDate={setStartDate}
							closeModal={closeModal}
							showCouponInput={activeStep === Steps.PAYMENT}
						/>
					</Box>
				)}
			</Box>
		</Container>
	);
};

export default UpgradeSubscription;
