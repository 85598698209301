import { useCallback, useEffect, useMemo, useState } from "react";

import { ExternalIntegrationIds } from "@remar/shared/dist/constants";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import ReactPixel from "react-facebook-pixel";

import { useHistory } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "store";

import {
	changeSubscriptionType,
	selectInactiveSubscription,
	setError,
	setSelectRenewalSubscription,
	setSelectSubModal,
	setUpgradeModal
} from "store/features/Auth/authSlice";
import {
	renewSubscription,
	selectSubcriptionIsLoading,
	upgradeSubscription
} from "store/features/MyAccount/myAccountSlice";

import { setBannerActionLoading } from "store/features/notifications/notifications.slice";

import { PaymentSchema, ShippingSchema } from "modules/Auth/components/schemas";
import { PixelEvent, getCallbackUrl, setTiktokEvent } from "modules/utils";

const initialFormValues = {
	countryId: "",
	address1: "",
	address2: "",
	city: "",
	state: "",
	zip: "",
	phoneNumber: ""
};

interface InType {
	onSubmit: () => void;
	isRenewSubscription?: boolean;
}

interface OutType {
	closeModal;
	handleSubmit;
	handleNext;
	handleBack;
	validationSchema;
	initialFormValues;
	subcriptionIsLoading;
	error;
	activeStep;
	setActiveStep;
	initStep?: number;
}

export const useUpgradeForm = ({ onSubmit, isRenewSubscription }: InType): OutType => {
	const stripe = useStripe();
	const dispatch = useAppDispatch();
	const elements = useElements();
	const history = useHistory();

	const initStep = useMemo(() => (isRenewSubscription ? 1 : 0), [isRenewSubscription]);
	const [activeStep, setActiveStep] = useState(initStep);

	const subcriptionIsLoading = useAppSelector(selectSubcriptionIsLoading);
	const { subscriptionId } = useAppSelector(selectInactiveSubscription);
	const {
		errorMessage: error,
		validatedCoupon,
		inactiveSubscription: { nextUserSubscriptionType: activeSubscription },
		paymentDiscount,
		selectSubModal
	}: RootState["auth"] = useAppSelector((store: RootState) => store.auth); //todo: refactor using getFull... selector
	const { userInfo } = useAppSelector((store: RootState) => store.myAccount); //todo: refactor using getFull... selector

	const callBackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);

	useEffect(() => {
		setActiveStep(initStep);
	}, [initStep]);

	useEffect(() => {
		if (activeSubscription) {
			dispatch(changeSubscriptionType(activeSubscription?.id as number));
		}
	}, [activeSubscription, dispatch]);

	const validationSchema = useMemo(() => {
		switch (activeStep) {
			case 1:
				return ShippingSchema;
			case 2:
				return PaymentSchema;
		}
	}, [activeStep]);

	const handleNext = (values: typeof initialFormValues) => {
		switch (activeStep) {
			case 1:
				setActiveStep(2);
				break;
			case 2:
				selectSubModal ? handleSelectNextSubscription() : handleSubmit(values);
				break;
		}
	};

	const upgradeSideEffect = useCallback(
		values => {
			// hotfix: activeSubscription is undefined after qb only subscription upgrade
			if (activeSubscription) {
				const priceData = activeSubscription!.subTypeEIDItems?.find(
					({ integrationId }) => integrationId == ExternalIntegrationIds.Stripe
				)?.data;
				const { price } = priceData;
				ReactPixel.track(PixelEvent.Purchase, { ...values, value: price, currency: "USD" });
				setTiktokEvent(PixelEvent.CompletePayment, { ...values, value: price, currency: "USD" });
			}

			if (callBackUrl) {
				window.location.replace(callBackUrl);
			} else {
				onSubmit();
			}
		},
		[activeSubscription, callBackUrl, onSubmit]
	);

	const renewSideEffect = useCallback(() => {
		if (callBackUrl) {
			window.location.replace(callBackUrl);
		} else {
			onSubmit();
		}
	}, [callBackUrl, onSubmit]);

	const handleBack = () => {
		setActiveStep(prevActiveStep => (prevActiveStep >= 1 ? prevActiveStep - 1 : 0));
	};

	const handleSelectNextSubscription = () => {
		dispatch(setError(""));
		renewSideEffect();
	};

	const handleSubmit = (values: typeof initialFormValues) => {
		const couponCode = validatedCoupon && validatedCoupon.coupon.code;
		const paymentAndDiscountId = paymentDiscount?.id;
		dispatch(setError(""));
		if (isRenewSubscription) {
			dispatch(
				renewSubscription({
					id: subscriptionId as number,
					subscriptionBody: {
						...values,
						zipCode: values.zip,
						userId: userInfo?.shippingDetails?.userId,
						fullName: userInfo?.shippingDetails?.fullName || userInfo!.firstName + userInfo!.lastName || ""
					},
					dispatch,
					sideEffect: renewSideEffect,
					handleError: setError,
					CardElement,
					elements,
					stripe,
					couponCode
				})
			);
		} else {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(
				upgradeSubscription({
					CardElement,
					elements,
					stripe,
					values,
					sideEffect: upgradeSideEffect,
					couponCode,
					paymentAndDiscountId
				})
			).then(r => {
				if (r.error) {
					dispatch(setError(r.error.message));
					setActiveStep(3);
				}
			});
		}
	};

	const closeModal = () => {
		dispatch(setUpgradeModal(false));
		dispatch(setBannerActionLoading(false));
		dispatch(setSelectRenewalSubscription(false));
		dispatch(setSelectSubModal(false));
		setActiveStep(isRenewSubscription ? 1 : 0);
	};

	return {
		closeModal,
		handleSubmit,
		handleNext,
		handleBack,
		validationSchema,
		initialFormValues,
		subcriptionIsLoading,
		error,
		activeStep,
		setActiveStep,
		initStep
	};
};
