import React, { useEffect, useMemo, useState } from "react";

import { Box, Card, Modal, useTheme } from "@material-ui/core";
import { ErrorSharp } from "@material-ui/icons";
import Button from "@remar/shared/dist/components/Button";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js/types/stripe-js/elements";
import { useHistory, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store";
import { getFullState, getPaymentDetails, setError, updatePaymentMethod } from "store/features/Billing/BillingSlice";

import { ManageText } from "modules/Auth/components/styles";
import { getCardIcon } from "modules/Auth/components/utils";

import { getCallbackUrl } from "modules/utils";

import EditAddPaymentMethod from "./EditAddPaymentMethod";

import { CardName, PaymentDescription, PaymentExpiredText, PaymentTitle } from "./styles";

interface PaymentUpdateThunk {
	CardElement: typeof CardElement;
	element: (card: typeof CardElement) => StripeCardElement;
	stripe: ReturnType<typeof useStripe>;
	sideEffect: (error: string) => void;
}
const PaymentMethods = ({ onEdit, showPrefilledInfo = false, showRecurringInfo = false }) => {
	const theme = useTheme<IExtendedTheme>();
	const dispatch = useAppDispatch();
	const stripe = useStripe();
	const { action } = useParams();
	const elements = useElements();
	const history = useHistory();
	const callBackUrl = useMemo(() => getCallbackUrl(history.location.search), [history.location.search]);

	const [modal, setModal] = useState(false);

	const { isLoading, paymentMethods } = useAppSelector(getFullState);

	useEffect(() => {
		dispatch(getPaymentDetails());
	}, [dispatch]);

	useEffect(() => {
		if (CardElement && action === "editCard") {
			setModal(true);
		}
	}, [action]);

	const onSubmit = () => {
		const updatePayload = {
			CardElement,
			stripe,
			element: elements?.getElement,
			sideEffect: (error: string) => {
				if (error) {
					dispatch(setError(error));
				} else {
					setModal(false);
					dispatch(getPaymentDetails());
					if (callBackUrl) {
						window.location.replace(callBackUrl);
					}
				}
			}
		};
		dispatch(updatePaymentMethod(updatePayload as PaymentUpdateThunk));
	};

	const edit = () => {
		onEdit();
		setModal(true);
	};

	return (
		<>
			<Card style={{ marginTop: 10 }}>
				<PaymentTitle>Payment Method</PaymentTitle>
				{!isLoading && !!paymentMethods && (
					<Box bgcolor={theme.palette.background.default} mt={2} p={2} borderRadius="4px">
						<Box display="flex" mb={2} alignItems="center">
							{getCardIcon(paymentMethods.brand)}
							<Box ml={2}>
								<Box display="flex" flexWrap="wrap">
									<CardName>{paymentMethods?.brand}</CardName>
									<CardName>{`.... ${paymentMethods?.last4}`}</CardName>
								</Box>
								<PaymentExpiredText>{`Expiration date: ${paymentMethods?.exp_month}/${paymentMethods?.exp_year}`}</PaymentExpiredText>
							</Box>
						</Box>
						<Button color="primary" onClick={edit}>
							{showPrefilledInfo ? "Change Card" : "Edit Card"}
						</Button>
						{showRecurringInfo && (
							<>
								<Box height="1px" bgcolor="#22252d" mt={1} mb={1} />
								<Box display="flex" mb={1}>
									<ErrorSharp style={{ fill: "#898f9e" }} />
									<PaymentDescription>
										Recurring payment is a payment model where the customers authorize the merchant to pull funds from
										their accounts automatically at regular intervals.
									</PaymentDescription>
								</Box>
							</>
						)}
					</Box>
				)}
				{!paymentMethods && (
					<Box display={"column"}>
						<Box display="flex" mb={1} mt={1} style={{ border: "solid", padding: "8px" }}>
							<ErrorSharp style={{ fill: "#898f9e" }} />
							<PaymentDescription>Your payment method will show up here once added</PaymentDescription>
						</Box>
						<Button onClick={edit}>
							<ManageText>Add Card</ManageText>
						</Button>
					</Box>
				)}
			</Card>
			<Modal open={modal} onClose={() => setModal(false)}>
				<EditAddPaymentMethod handleSubmit={onSubmit} closeModal={() => setModal(false)} CardElement={CardElement} />
			</Modal>
		</>
	);
};
export default PaymentMethods;
