export const ERROR_FIELD_COLOR = "error.main";

export enum MaintenanceModeTypes {
	OFF = "off",
	SEVERE = "severe",
	OPTIONAL = "optional"
}

export enum ExternalIntegrationIds {
	Stripe = 1,
	ShipStation = 2,
	DigitalAsset = 3,
	PBS = 4,
	Apple = 5
}

export enum AddonsIntegrationIds {
	PhysicalAsset = 2,
	DigitalAsset = 3
}

export enum SignUpSteps {
	gettingStarted = "getting_Started",
	coursePackage = "course_package",
	studentManagement = "student_management",
	customizeLocation = "customize_Location",
	payment = "payment"
}

export enum SignupStepsLabels {
	account = "Account",
	shipping = "Shipping",
	payment = "Payment"
}

export const GLOBAL_CONSTANTS = {
	BACKEND_API: process.env.REACT_APP_BACKEND_API,
	TINY_MCE_API_KEY: process.env.REACT_APP_TINY_MCE_API_KEY,
	CONTACT_US_EMAIL: process.env.REACT_APP_CONTACT_US_EMAIL,
	COOKIE_USE_LINK: process.env.REACT_APP_COOKIE_USE_LINK,
	COURSES: "/courses",
	JOB_SECRET: process.env.REACT_APP_JOB_SECRET,
	LESSONS: "/lessons",
	LOCAL_STORAGE_PREFIX: process.env.REACT_APP_LOCAL_STORAGE_PREFIX,
	LOG_URL: "/client-log",
	MAIL_ENCRYPTION_LINK_KEY: process.env.REACT_APP_MAIL_ENCRYPTION_LINK_SECRET_KEY,
	MARKETING_WEBSITE: process.env.REACT_APP_MARKETING_WEBSITE,
	PRIVACY_POLICY_LINK: process.env.REACT_APP_PRIVACY_POLICY_LINK,
	SUBJECT_CATEGORIES: "/subject-categories",
	TERMS_AND_CONDITIONS_LINK: process.env.REACT_APP_TERMS_AND_CONDITIONS_LINK,
	UPLOAD_URL: "/upload",
	UPGRADE: `/upgrade?step=${SignUpSteps.gettingStarted}`
};

export enum LessonRanks {
	One = 1,
	Two = 2,
	Three = 3
}

export enum LessonTypes {
	VideoOnly = 1,
	Interactive = 2,
	TestOnly = 3
}

export enum LessonVideoSegmentTypes {
	Correct = "correct",
	Incorrect = "incorrect",
	Loop = "loop",
	Start = "start",
	Question = "displayQuestion"
}

export enum LessonVideoSegmentTypeIds {
	Start = 1,
	Loop = 2,
	Correct = 3,
	Incorrect = 4,
	Question = 5
}

export const LessonVideoSegmentTypeIdMap = {
	[LessonVideoSegmentTypeIds.Start]: LessonVideoSegmentTypes.Start,
	[LessonVideoSegmentTypeIds.Question]: LessonVideoSegmentTypes.Question,
	[LessonVideoSegmentTypeIds.Loop]: LessonVideoSegmentTypes.Loop,
	[LessonVideoSegmentTypeIds.Correct]: LessonVideoSegmentTypes.Correct,
	[LessonVideoSegmentTypeIds.Incorrect]: LessonVideoSegmentTypes.Incorrect
};

export enum QuestionDifficultyLevels {
	VeryEasy = 1,
	Easy = 2,
	Normal = 3,
	Hard = 4,
	VeryHard = 5
}

export enum QuestionTypes {
	SingleChoice = 1,
	MultipleChoice = 2,
	FreeTextShort = 3,
	FreeTextLong = 4,
	PrioritizedMultipleChoice = 5,
	Grouping = 6,
	Sequencing = 7,
	DragAndDrop = 8,
	CaseStudy = 9,
	ClozeDropDown = 10,
	MatrixSingleChoice = 11,
	MatrixMultipleChoice = 12,
	DropDownTable = 13,
	BowTie = 14,
	MultipleChoiceSN = 15,
	MultipleChoiceSATA = 16,
	MultipleResponseGroup = 17,
	RationalScoringDragAndDrop = 18,
	RationalScoringDropDown = 19,
	HotspotHighlight = 20,
	HighlightTable = 21,
	Neutral = 22
}

export enum QuestionBankTestModes {
	Tutor = 1,
	Test = 2,
	CAT = 3
}

export enum UserTypes {
	Admin = 1,
	RegularStudent = 2,
	GuestUser = 3,
	InstitutionAdmin = 4,
	LocationInvite = 5
}
export enum LocationEnum {
	Main = 1,
	Common = 2
}

export enum UserAuthTypes {
	Normal = 1,
	GoogleLogin = 2,
	FacebookLogin = 3
}

export enum StripePaymentEventSatuses {
	Success = "succeeded",
	Failure = "payment_failed",
	RequiresConfirmation = "requires_confirmation",
	RequiresAction = "requires_action",
	PaymentRejected = "requires_payment_method"
}

export enum UserSubscriptionTypeCategoriesEnum {
	Course = 1,
	LocationPackage = 2,
	LocationPerSeat = 3,
	QuestionBank = 4
}

export enum LocationPackagesTypeEnum {
	Package1 = 1,
	Package2 = 2,
	Package12 = 3
}

export enum UserPaymentType {
	Subscription = 1,
	Book = 2,
	CAT = 3,
	LocationSeat = 4,
	LocationTheme = 5,
	InvitedUser = 6,
	Refunded = 7
}

export enum UserCustomTestTypes {
	Tutor = 1,
	Test = 2,
	CAT = 3
}

export enum QuestionDifficultyLevelTiers {
	Easy = 1,
	Moderate = 2,
	Hard = 3
}

export enum NotificationTypes {
	System = 1,
	Custom = 2
}

export enum NotificationTypesLabels {
	System = "System",
	Custom = "Custom"
}
export enum ContentAccessTypes {
	Paid = 1,
	Mixed = 2,
	Trial = 3
}
export enum ContentAccessTypesLabels {
	Paid = "Paid",
	Mixed = "Mixed",
	Trial = "Trial"
}

export enum UserCodeEnum {
	ForgotPassword = "0",
	EmailVerification = "1",
	GuestUserClaimCode = "2",
	LocationSeatInvite = "3",
	AdminResetPassword = "4"
}

export const ADMIN_USER_ID = 1;

export const SET_START = "setStart";
export const SET_LOOP = "setLoop";
export const SET_CORRECT_RES = "setCorrectRes";
export const SET_INCORRECT_RES = "setIncorrectRes";
export const PREVIEW = "preview";

export const actionKey = [SET_START, SET_LOOP, SET_CORRECT_RES, SET_INCORRECT_RES, PREVIEW];

export const FROM = "from";
export const TO = "to";

export const MAX_GROUPING_ANSWER_OPTIONS = 9;
export const MAX_TESTONLY_QUESTION_TYPES_OPTS_LENGTH = 6;
export const MAX_COURSE_ADDONS = 10;
export const MAX_VIT_ANSWER_OPTIONS = 5;
export const MAX_ANSWER_OPTIONS_TEXT_LENGTH = 35;
export const CUSTOM_MAX_ANSWER_OPTIONS_TEXT_LENGTH = 20;
export const MAX_GROUP_TEXT_LENGTH = 90;
export const MAX_TAB_CHARACTER_LENGTH = 15;
export const YYYY_MM_DD = "yyyy-MM-dd";
export const KK_MM_AA = "KK:mm aa";
export const COUNTDOWN_STATUS = {
	STARTED: "Started",
	STOPPED: "Stopped"
};

export const WHOM_AM_I_URL = "whoami";
export const LOGIN = "login?";
export const maintenancePath = "/maintenance";
